import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import FileStrageContainer from '../../containers/fileStrage/fileStrageContainer';
import DraggableDialog from '../common/modal';
import { getMrCheck } from '../../commonFunction/getEnv';

const useStyles = makeStyles((theme) => ({
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'calc(100% - 16px)',
    borderRadius: '20px 0 0 20px',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    background: '#f3f3f3',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));
export default function FileStrage(props) {
  const { id, styles, isDisabled } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        className={`${styles} ${id === 'menufilestrage' && classes.child}`}
        onClick={() => { setOpen(true); }}
        disabled={isDisabled || getMrCheck()}
      >
        ファイルストレージ
      </Button>
      <DraggableDialog
        title="ファイルストレージ"
        open={open}
        onClose={onClose}
        maxWidth={1200}
      >
        <FileStrageContainer />
      </DraggableDialog>
    </>
  );
}
