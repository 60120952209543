import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import deepEqual from 'deep-equal';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
// eslint-disable-next-line import/no-cycle
import CustomerDetailButtonIcon from './parts/customerDetailButtonIcon';
import CustomerDetailDrive from './parts/customerDetailDrive';
import CustomerDetailName from './parts/customerDetailName';
import CustomerDetailContact from './parts/customerDetailContact';
import CustomerDetailAddress from './parts/customerDetailAddress';
import CustomerDetailFamily from './parts/customerDetailFamily';
import CustomerDetailWorkPlace from './parts/customerDetailWorkPlace';
import CustomerDetailBudget from './parts/customerDetailBudget';
import CustomerDetailHope from './parts/customerDetailHope';
import CustomerDetailPresentCondition from './parts/customerDetailPresentCondition';
import CustomerDetailResponse from './parts/customerDetailResponse';
import CustomerDetailInHouse from './parts/customerDetailInHouse';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import Modal from '../modal';

import { getEnvVariable, getMrCheck } from '../../../commonFunction/getEnv';
import { CUSTOMER_STATUS_CONTRACT } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
    '& .MuiDialog-paper': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 0,
        borderRadius: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'none',
      },
    },
  },
  paper: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 1144,
      minWidth: 1144,
      background: '#fff',
    },
  },
  idHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#f3f3f3',
    zIndex: 1,
    padding: '24px 32px 16px',
    borderBottom: '1px #c8c8c8 solid',
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: 2,
    },
    [commonTheme.breakpoints.up('md')]: {
      transform: 'translateY(-25px)',
      margin: '-25px -24px 16px',
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 24px 8px',
    },
  },
  content: {
    [commonTheme.breakpoints.up('md')]: {
      background: '#F3F3F3',
    },
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  contentId: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: 118,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTypography-root': {
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  contentIdNo: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.up('md')]: {
      marginLeft: 4,
    },
  },
  footer: {
    padding: '16px 0',
  },
  footerBtn: {
    margin: '0 auto',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 19px',
  },
  button: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 'auto',
    width: '100%',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: '#fff',
    },
  },
  ttl: {
    whiteSpace: 'nowrap',
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    whiteSpace: 'nowrap',
  },
  // モーダル
  paper2: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
}));

function getChangedData(newData, oldData) {
  const data = {};
  Object.keys(newData).forEach((key) => {
    if (!deepEqual(newData[key], oldData[key])) {
      data[key] = newData[key];
    }
  });
  return data;
}

export default function CustomerDetail(props) {
  const {
    open,
    onClose,
    input,
    onChange,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const {
    current,
    updateCurrent,
    updateOriginal,
    update,
  } = useUpdate(input.customerDetail);

  const [detail, setDetail] = useState(input.customerDetail);

  const [errorSet, setErrorSet] = useState(new Set());
  const [isDisable, setIsDisable] = useState(false);

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const setData = (newData) => {
    updateCurrent(newData);
  };

  const updateFunction = async () => {
    if (isChanged(input.customerDetail, current)) {
      setIsDisable(true);
      const newData = getChangedData(current, input.customerDetail);
      if (('wishArea' in newData) && !('wishAreaCode' in newData)) {
        newData.wishAreaCode = current.wishAreaCode;
      }
      // 更新
      await update(newData, false, { isShowLoading: true, isShowMessage: false })
        .then((res) => {
          if (res
              && Number(current.customerStatusCode) === CUSTOMER_STATUS_CONTRACT
              && !getMrCheck()) {
            // キマリス
            const url = `${getEnvVariable('KEYMALIS_BASE_URL')}contract/add?marketingCustomerId=${current.customerId}`;
            window.open(url, '_blank');
          }
        });
      // 顧客詳細を閉じる
      onClose();
      // 顧客メインの表示を変更
      onChange?.(current);

      setIsDisable(false);
      return;
    }
    // 顧客詳細を閉じる
    onClose();
  };

  // メールアドレス 確認ウインドウ
  const confirmOpenMail = () => {
    const click1 = () => {
      console.log('CANCEL');
    };
    const click2 = () => {
      // 更新処理
      updateFunction();
    };
    dispatch(changeConfirmMessage({
      title: '変更確認ウィンドウ',
      msgList: ['変更前のメールアドレス宛に送信予約されているメールは全てキャンセルされます'],
      buttons: [
        {
          label: 'CANCEL',
          set: click1,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: click2,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const onKeep = async () => {
    if (
      (input.customerDetail.mail1 && input.customerDetail.mail1 !== current.mail1)
      || (input.customerDetail.mail2 && input.customerDetail.mail2 !== current.mail2)
      || (input.customerDetail.mail3 && input.customerDetail.mail3 !== current.mail3)
      || (input.customerDetail.mail4 && input.customerDetail.mail4 !== current.mail4)
      || (input.customerDetail.isMailSend1
        && input.customerDetail.isMailSend1 !== current.isMailSend1
        && current.isMailSend1 === 0)
      || (input.customerDetail.isMailSend2
        && input.customerDetail.isMailSend2 !== current.isMailSend2
        && current.isMailSend2 === 0)
      || (input.customerDetail.isMailSend3
        && input.customerDetail.isMailSend3 !== current.isMailSend3
        && current.isMailSend3 === 0)
      || (input.customerDetail.isMailSend4
        && input.customerDetail.isMailSend4 !== current.isMailSend4
        && current.isMailSend4 === 0)
    ) {
      confirmOpenMail();
    } else {
      // 更新処理
      updateFunction();
    }
  };

  const reset = () => {
    // 初期化（顧客詳細に表示する情報）
    // 初期化（更新用のデータ）
    updateCurrent(input.customerDetail);
    updateOriginal(input.customerDetail);
    // 顧客詳細を閉じる
    onClose();
  };

  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '顧客情報が変更されています。',
      msgList: ['変更を保存しますか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: reset,
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: onKeep,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const onCloseBg = () => {
    if (isChanged(input.customerDetail, current)) {
      // データが変更されている場合、確認ウインドウを表示
      confirmOpen();
    } else {
      reset();
    }
  };

  const onClickGraph = () => {
    if (input.customerDetail !== current) {
      // データが変更されている場合、確認ウインドウを表示
      confirmOpen();
    } else {
      // 顧客詳細を閉じる
      onClose();
    }
    input.setTab(6);
  };

  const data = {
    dataInput: {
      ...input,
      setData,
    },
    detail,
    setDetail,
    graph: onClickGraph,
    errorCallback,
  };

  const body = (
    <Modal
      title={['顧客詳細ウィンドウ', '顧客詳細']}
      open={open}
      onClose={onCloseBg}
      buttons={{
        label: '保存',
        onClick: onKeep,
        disabled: errorSet.size !== 0 || isDisable,
      }}
      width={1180}
    >
      <Grid className={classes.content}>
        <Grid className={`${classes.wrap} ${classes.idHead}`}>
          <Grid className={classes.contentId}>
            <Typography className={baseClasses.title4}>顧客ID</Typography>
            <Typography className={classes.contentIdNo}>
              {input.customerDetail.customerId}
            </Typography>
          </Grid>
          <CustomerDetailButtonIcon input={data} />
        </Grid>
        <CustomerDetailDrive input={data} />
        <CustomerDetailName input={data} />
        <CustomerDetailContact input={data} />
        <CustomerDetailAddress input={data} />
        <CustomerDetailFamily input={data} />
        <CustomerDetailWorkPlace input={data} />
        <CustomerDetailBudget input={data} />
        <CustomerDetailHope input={data} />
        <CustomerDetailPresentCondition input={data} />
        <CustomerDetailResponse input={data} />
        <CustomerDetailInHouse input={data} />
      </Grid>
    </Modal>
  );

  return (
    <div>
      {body}
    </div>
  );
}
