import { Grid, makeStyles } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import commonStyles from '../../components/styles';

import LibraryCreateComponent from '../../components/mailLibrary/libraryCreate/libraryCreateComponent';
import libraryGetApi from '../../apis/library/libraryGetApi';
import libraryUpdateApi from '../../apis/library/libraryUpdateApi';
import libraryResisterApi from '../../apis/library/libraryResisterApi';
import sendmailTestApi from '../../apis/sendmail/sendmailTestApi';
import Dialog from '../../components/common/modal';
import store from '../../store';
import { useLoading } from '../../hooks';

import { INFO_OH_OFFICE, MAIL_CODE_PRIVATE, MAIL_FROM_INFO, MAIL_FROM_SELF, MAIL_TYPE_SIMULTANEOUS_SEND, MAIL_TYPE_SIMULTANEOUS_TIMER } from '../../constants';
import { MAIL_TEMPLATE_ADMIN } from '../../constants/userRole';
import { LIBRARY_EDIT_WINDOW, MAIL_SENDING, TESTMAIL_SENDING } from '../../constants/loading';
import { MARKETING_GROUPS } from '../../constants/userRoleGroup';
import { changeConfirmMessage } from '../../store/eleCommon/customConfirmMessage';
import getSendRecommendMails from '../../apis/sendmail/sendmailRecommendMailsGetApi';
import getSendLibraryMails from '../../apis/sendmail/sendmailLibraryMailsGetApi';
import UserTree from '../../components/common/userTreeNew';
import postSendLibraryMails from '../../apis/sendmail/sendmailLibraryMailsPostApi';
import postSendRecommendMails from '../../apis/sendmail/sendmailRecommendMailsPostApi';
import { isOpen } from '../../store/common/apiMessageSlice';
import { validateJsonSize } from '../../commonFunction/validations';
import { getEnvVariable } from '../../commonFunction/getEnv';

const useStyles = makeStyles({
  disable: {
    pointerEvents: 'none',
    '& > span': { color: '#c8c8c8' },
  },
  none: {
    display: 'none',
  },
});

export default function LibraryCreateContainer(props) {
  const {
    open,
    edit,
    onClose,
    selectDir,
    reloadLibraries = () => {},
    initialMailTitle = null, // メール画面からライブラリを保存する際に使用
    initialMailBody = null, // メール画面からライブラリを保存する際に使用
  } = props;

  const dispatch = useDispatch();

  const common = commonStyles();
  const classes = useStyles();
  const { addLoading, removeLoading, hasLoading } = useLoading();
  const { responseHeader } = store.getState();

  const [errorSet, setErrorSet] = useState(new Set());
  const [userTreeOpen, setUserTreeOpen] = useState(false);

  // テスト送信フラグ
  const [testSendFlg, setTestSendFlg] = useState(false);

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };
  const initialData = {
    libraryDirectoryId: selectDir.libraryDirectoryId, // ライブラリディレクトリID/ライブラリツリーから渡される
    userId: responseHeader.userId, // ユーザID(ライブラリの担当者)
    libraryId: null,
    libraryName: '', // ライブラリ名
    libraryMemo: '', // ライブラリメモ
    mailSubject: initialMailTitle ?? `{customer_lastName}様／${getEnvVariable('APP_LOGICAL_NAME')} {myLastName}`, // 件名
    mailBody: initialMailBody ?? '', // 本文
    mdailBodyCount: 0,
    product: {
      productName: '',
      siteId: undefined, // 物件番号
    },
    productMemo: '', // 物件名
    attachments: [],
  };

  const [data, setData] = useState(initialData);
  const onCloseFunc = () => {
    setData(initialData);
    onClose();
  };

  const isPrivateOwnDir = selectDir.mailCode === MAIL_CODE_PRIVATE
    && selectDir.userId === responseHeader.userId;
  const isEditable = isPrivateOwnDir
    || data.userId === responseHeader.userId
    || responseHeader.roleIds.includes(MAIL_TEMPLATE_ADMIN);

  // GET /libraries/{libraryId} 【library001】ライブラリ情報取得
  const getLibraryFunction = async (libraryId) => {
    await libraryGetApi(libraryId)
      .then(async (res) => {
        setData({
          ...data,
          libraryId: edit.libraryId,
          libraryDirectoryId: res.data.libraryDirectoryId,
          libraryName: res.data.libraryName || '',
          libraryMemo: res.data.libraryMemo || '',
          userId: res.data.userId,
          mailSubject: res.data.mailSubject || '',
          mailBody: res.data.mailBody || '',
          mdailBodyCount: data.mdailBodyCount + 1,
          product: {
            productName: res.data.productName || '',
            siteId: res.data.siteId,
          },
          productMemo: res.data.productMemo || '',
          attachments: res.data.attachments || [],
          // attachments: res.data.attachments || [311, 312],
        });
      })
      .catch((err) => console.error(err));
  };

  // PUT /libraries/management/{libraryId} 【library003】ライブラリ情報更新
  const libraryUpdateFunction = async (libraryID, modifyObj) => {
    await libraryUpdateApi(libraryID, modifyObj, LIBRARY_EDIT_WINDOW)
      .then(async (res) => console.log(res))
      .catch((err) => console.log(err));
  };

  // POST /libraries/management 【library003】ライブラリ情報登録
  const libraryCreateFunction = async (modifyObj) => {
    await libraryResisterApi(modifyObj)
      .then(async (res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // テスト送信
  const sendTest = async () => {
    addLoading(TESTMAIL_SENDING);
    const params = {};
    params.mailSubject = data.mailSubject; // メール件名
    params.mailBody = data.mailBody; // メール本文
    params.files = data.attachments; // 添付ファイルのファイル名
    params.mailFromCode = MAIL_FROM_SELF; // 自分
    if (data.libraryId) {
      params.libraryId = data.libraryId; // 編集の場合
    }
    params.isNotSetCustomer = 1; // 送信先顧客未設定フラグ
    // console.log('テスト送信', { data, params });
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }
    await sendmailTestApi(params);
    removeLoading(TESTMAIL_SENDING);
    setTestSendFlg(true);
  };

  // 保存
  const save = async () => {
    const params = { ...data };
    params.siteId = data.product.siteId ? data.product.siteId : undefined;
    if (edit) params.isUpdate = 1;
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      return;
    }
    onClose();
    if (edit) {
      // 編集
      await libraryUpdateFunction(data.libraryId, params);
    } else {
      // 新規
      await libraryCreateFunction({ libraries: [params] });
    }
    reloadLibraries();
  };
  // 送信
  const sendLibraryMailHandler = async () => {
    addLoading(MAIL_SENDING);
    const params = {
      mailFromCode: MAIL_FROM_INFO,
      infoCode: INFO_OH_OFFICE,
    };
    params.libraryId = data.libraryId; // 画面[ライブラリ編集](GA-054)で選択しているライブラリID
    params.mailSubject = data.mailSubject; // 項目[件名](画面[ライブラリ編集](GA-054))
    params.mailBody = data.mailBody; // 項目[本文](画面[ライブラリ編集](GA-054))
    params.mailTypeCode = data.sendStartDate !== '' ? MAIL_TYPE_SIMULTANEOUS_TIMER : MAIL_TYPE_SIMULTANEOUS_SEND; // 項目[送信開始時間](画面[ライブラリ編集](GA-054))に値があれば「4」なければ「3」
    params.signatureLibraryId = data.libraryId; // 画面[ライブラリ編集](GA-054)で選択しているライブラリID
    params.timerStartAt = data.sendStartDate; // 項目[送信開始時間](画面[ライブラリ編集](GA-054))に値があれば「4」なければ「3」
    params.files = data.attachments; // 項目[添付](画面[ライブラリ編集](GA-054))
    params.limit = data.limit; // 項目[通数制限](画面[ライブラリ編集](GA-054))
    params.divisionIds = data.divisionIds; // 項目[通数制限](画面[ライブラリ編集](GA-054))
    onClose();
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      removeLoading();
      return;
    }
    await postSendLibraryMails(params)
      .then((res) => {
        const { message, description } = res;
        const msg = { message, description, status: 'success' };
        dispatch(isOpen(msg));
        setData(initialData);
      })
      .catch(err => {
        console.error({ err });
      })
      .finally(() => removeLoading(MAIL_SENDING));
  };
  const sendRecommendMailHandler = async () => {
    addLoading(MAIL_SENDING);
    const params = {
      mailFromCode: MAIL_FROM_INFO,
      infoCode: INFO_OH_OFFICE,
    };
    params.libraryId = data.libraryId; // 画面[ライブラリ編集](GA-054)で選択しているライブラリID
    params.mailSubject = data.mailSubject; // 項目[件名](画面[ライブラリ編集](GA-054))
    params.mailBody = data.mailBody; // 項目[本文](画面[ライブラリ編集](GA-054))
    params.mailTypeCode = data.sendStartDate !== '' ? MAIL_TYPE_SIMULTANEOUS_TIMER : MAIL_TYPE_SIMULTANEOUS_SEND; // 項目[送信開始時間](画面[ライブラリ編集](GA-054))に値があれば「4」なければ「3」
    params.signatureLibraryId = data.libraryId; // 画面[ライブラリ編集](GA-054)で選択しているライブラリID
    params.timerStartAt = data.sendStartDate; // 項目[送信開始時間](画面[ライブラリ編集](GA-054))に値があれば「4」なければ「3」
    params.files = data.attachments; // 項目[添付](画面[ライブラリ編集](GA-054))
    params.limit = data.limit; // 項目[通数制限](画面[ライブラリ編集](GA-054))
    onClose();
    // サイズチェック
    const error = validateJsonSize(params);
    if (error) {
      dispatch(isOpen({
        message: error,
        status: 'error',
      }));
      removeLoading();
      return;
    }
    await postSendRecommendMails(params)
      .then((res) => {
        const { message, description } = res;
        const msg = { message, description, status: 'success' };
        dispatch(isOpen(msg));
        setData(initialData);
      })
      .catch(err => {
        console.error({ err });
      })
      .finally(() => removeLoading(MAIL_SENDING));
  };
  // 送信確認 GET /send-recommend-mails
  const sendLibraryConfirm = async () => {
    const param = {
      divisionIds: data.divisionIds,
      timerStartAt: data.sendStartDate,
    };
    if (data.limit) { param.limit = data.limit; }
    await getSendLibraryMails(param)
      .then(async (res) => {
        dispatch(changeConfirmMessage({
          title: '確認',
          msgList: [`${res.data.count}人にメールを送信します。`, 'よろしいですか？'],
          buttons: [
            {
              label: 'CANCEL',
              set: () => { },
              classes: common.buttonsSecondary,
            },
            {
              label: 'OK',
              set: () => {
                sendLibraryMailHandler();
              },
              classes: common.buttonsPrimary,
            },
          ],
        }));
      })
      .catch((err) => console.log(err));
  };
  const sendRecommendConfirm = async () => {
    // 「山口作成_レコメンドメール配信」ディレクトリ直下の場合
    const param = {
      timerStartAt: data.sendStartDate,
    };
    if (data.limit) { param.limit = data.limit; }
    await getSendRecommendMails(param)
      .then(async (res) => {
        dispatch(changeConfirmMessage({
          title: '確認',
          msgList: [`${res.data.count}人にメールを送信します。`, 'よろしいですか？'],
          buttons: [
            {
              label: 'CANCEL',
              set: () => { },
              classes: common.buttonsSecondary,
            },
            {
              label: 'OK',
              set: () => {
                sendRecommendMailHandler();
              },
              classes: common.buttonsPrimary,
            },
          ],
        }));
      })
      .catch((err) => console.log(err));
  };

  const sendConfirm = () => {
    if (selectDir.libraryDirectoryId === 20667) {
      // 「山口作成_レコメンドメール配信」ディレクトリ直下の場合
      sendRecommendConfirm();
    } else {
      setUserTreeOpen(true);
      // その他のレコメンド
    }
  };

  const setSelectDivision = (e) => {
    const divisions = [];
    for (let i = 0; e.length > i; i += 1) {
      divisions.push(e[i].divId);
    }
    setData({
      ...data,
      divisionIds: divisions,
    });
  };

  useEffect(async () => {
    if (edit && edit.libraryId && open) {
      getLibraryFunction(edit.libraryId);
    }
  }, [edit, open]);

  return (
    <Dialog
      open={open}
      onClose={onCloseFunc}
      title="ライブラリの作成・編集ウィンドウ"
      maxWidth="1160px"
      bodyClassName={classes.body}
      buttons={[
        {
          className: !data.mailSubject && classes.disable,
          label: 'テスト送信',
          onClick: sendTest,
          type: 'secondary',
          disabled: errorSet.size !== 0 || hasLoading(TESTMAIL_SENDING),
        },
        {
          className: !MARKETING_GROUPS.includes(responseHeader.roleGroupId) || !data.libraryId ? classes.none : '',
          label: '送信',
          onClick: sendConfirm,
          disabled: !data.libraryName || !data.mailSubject || !data.limit || !data.sendStartDate
            || !isEditable || errorSet.size !== 0 || !testSendFlg || hasLoading(MAIL_SENDING),
        },
        {
          label: '保存',
          onClick: save,
          disabled: !data.libraryName || !data.mailSubject || !isEditable || errorSet.size !== 0,
        },
      ]}
      buttonClose
    >
      <Grid
        container
        className={hasLoading(LIBRARY_EDIT_WINDOW) ? common.loading : ''}
      >
        <LibraryCreateComponent
          data={data}
          setData={setData}
          edit={edit}
          isDisable={!isEditable}
          errorCallback={errorCallback}
        />
      </Grid>
      <Dialog
        open={userTreeOpen}
        onClose={() => { setUserTreeOpen(false); }}
        buttons={[
          {
            label: '選択',
            onClick: sendLibraryConfirm,
            disabled: !data.divisionIds,
          },
        ]}
        maxWidth={400}
      >
        <UserTree
          showOnlyDivisions
          isMulti
          selectDivision={data.divisionIds}
          setSelectDivision={setSelectDivision}
        />
      </Dialog>
    </Dialog>
  );
}
