import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deepEqual from 'deep-equal';
import MailCreateWindowComponent from '../../components/mail/createWindowComponent';
import MailCreateWindowComponentSp from '../../components/mail/sp/createWindow';

import libraryGetApi from '../../apis/library/libraryGetApi';
import sendmailGetMailDetailApi from '../../apis/sendmail/sendmailGetMailDetailApi';
import sendmailUpdateApi from '../../apis/sendmail/sendmailUpdateApi';
import sendmailCancelApi from '../../apis/sendmail/sendmailCancelApi';
import { isOpen } from '../../store/common/apiMessageSlice';

import mailFunctions from './mailFunctions';
import { useLoading } from '../../hooks';

import { dateFormatter, getSessionStorage } from '../../commonFunction';
import { getFromChoice } from '../../commonFunction/mail';

import { MAIL_EDIT_WINDOW } from '../../constants/loading';

import {
  INFO_CODE,
  MAIL_FROM_SELF,
  MAIL_TYPE_INDIVIDUAL_SEND,
  MAIL_TYPE_INDIVIDUAL_TIMER,
  MAIL_TYPE_SIMULTANEOUS_SEND,
  MAIL_TYPE_SIMULTANEOUS_TIMER,
  MAIL_FROM_INFO,
} from '../../constants';
import {
  MAIL_SUBJECT,
  MAIL_BODY,
  MAIL_FROM_CODE,
  MAIL_SIGN,
  MAIL_ATTACHMENTS,
} from '../../constants/sessionStorage';
import { libraryGetSigniture } from '../../store/library/libraryGetSignitureSlice';
import { getEnvVariable } from '../../commonFunction/getEnv';

const tmpFunc = () => { };

export default function CreateWindowContainer(props) {
  const {
    open,
    user,
    handleClose,
    actionId,
    mailQueueId,
    mailAddress,
    defaultAddressesObj = {},
    refleshCallBack = tmpFunc,
    isLocalStorage = true,
    taioRirekiFlg = false,
    additionalMailParam,
    isActiveSaveTemplate,
  } = props;

  const { addLoading, removeLoading, hasLoading } = useLoading();
  const dispatch = useDispatch();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const defaultAddressesObjRef = useRef({});

  const allFlg = user.mails !== undefined; // 一斉メールかどうか

  const [rirekiAllRsvFlg, setRirekiAllRsvFlg] = useState(false); // 対応履歴かつ一斉予約メールかどうか

  const mailFromCodeRef = useRef();

  const {
    mainDivision,
    userId: currentUserId,
    roleIds,
  } = useSelector((s) => s.responseHeader);

  const initialSubject = isLocalStorage
    ? getSessionStorage(MAIL_SUBJECT, `{customer_lastName}様／${getEnvVariable('APP_LOGICAL_NAME')} {myLastName}`)
    : `{customer_lastName}様／${getEnvVariable('APP_LOGICAL_NAME')} {myLastName}`;
  // NOTE: Fromが担当課員などの場合、署名idが変わるのでデフォルトが表示されないので空オブジェクトを設定する
  const isShowSignature = getSessionStorage(MAIL_FROM_CODE, MAIL_FROM_SELF) === MAIL_FROM_SELF;
  const initialSignature = isLocalStorage
    && isShowSignature ? getSessionStorage(MAIL_SIGN, {}) : {};

  // 署名一覧を格納
  const signature = useSelector(
    (state) => state.libraryGetSigniture.libraryGetSigniture,
  );

  // info/sign/libraryはAPIで取得し、オブジェクト化するためこちらに記載
  const [mailSetting, setMailSetting] = useState({
    from: {
      constants: {
        menus: getFromChoice(roleIds),
        title: 'From',
      },
    },
    info: {
      constants: {
        menus: INFO_CODE,
        title: 'info',
      },
    },
    sign: {
      constants: {
        menus: {},
        title: 'sign',
      },
    },
    library: {
      constants: {
        menus: {},
        title: 'library',
      },
      // ディレクトリデータ(初回表示では、[/librarydir/folder/{librarydirid}]APIを呼び出す)
      libraryDirectories: [],
      // [ライブラリ]項目データ(ライブラリを選択後、[/library_micro/id/]APIを呼び出す)
      detail: {
        message: 'message',
        description: 'description',
        data: {},
      },
    },
  });

  const [editorState, setEditorState] = useState('');
  // テスト送信がされたかを判定→isTestSentは本送信ボタンのdisabled判定に使用
  const [isTestSent, setIsTestSent] = useState(false);

  // 一斉メールの場合は
  const customerId = !allFlg ? user.customerId : user.ids[0];
  // テストメールの送信パラメータ
  const initialBaseMailParam = {
    mailSubject: initialSubject,
    mailBody: isLocalStorage ? getSessionStorage(MAIL_BODY, '') : '',
    mailFromCode: isLocalStorage
      ? getSessionStorage(MAIL_FROM_CODE, MAIL_FROM_SELF)
      : MAIL_FROM_SELF,
    signatureLibraryId: initialSignature.libraryId || undefined,
    isNotSetCustomer: 0,
  };
  const initialTestMailParam = {
    ...initialBaseMailParam,
    customerId,
  };
  const [testMailParam, setTestMailParam] = useState(initialTestMailParam);
  const initialMailParam = {
    ...initialBaseMailParam,
    customerIds: !allFlg ? [customerId] : user.ids,
    mailTypeCode: !allFlg
      ? MAIL_TYPE_INDIVIDUAL_SEND
      : MAIL_TYPE_SIMULTANEOUS_SEND,
    ...additionalMailParam,
  };
  // メールの送信パラメータ
  const [mailParam, setMailParam] = useState(initialMailParam);

  const isMailNone = (mail) => {
    if (!mail) return true;
    if (mail === '-') return true;
    return false;
  };
  const getDefaultTo = () => {
    const defaultAddressList = Object.keys(defaultAddressesObj)
      .map((key) => defaultAddressesObj[key]);
    if (defaultAddressList.length !== 0) return defaultAddressList.join(', ');
    let result = '';
    if (user.isMailSend1) {
      result += user.mail1;
    }
    if (user.isMailSend2) {
      if (result !== '') { result += ', '; }
      result += user.mail2;
    }
    if (user.isMailSend3) {
      if (result !== '') { result += ', '; }
      result += user.mail3;
    }
    if (user.isMailSend4) {
      if (result !== '') { result += ', '; }
      result += user.mail4;
    }
    return result;
  };
  const getDefaultToCheck = () => {
    const defaultAddressCheckedList = Object.keys(defaultAddressesObj);
    if (defaultAddressCheckedList.length !== 0) {
      return {
        mail1: defaultAddressCheckedList.includes('mail1'),
        mail2: defaultAddressCheckedList.includes('mail2'),
        mail3: defaultAddressCheckedList.includes('mail3'),
        mail4: defaultAddressCheckedList.includes('mail4'),
      };
    }

    return {
      mail1: user.isMailSend1 === 1 && !isMailNone(user.mail1),
      mail2: user.isMailSend2 === 1 && !isMailNone(user.mail2),
      mail3: user.isMailSend3 === 1 && !isMailNone(user.mail3),
      mail4: user.isMailSend4 === 1 && !isMailNone(user.mail4),
    };
  };

  const initialMeta = {
    from: isLocalStorage
      ? getSessionStorage(MAIL_FROM_CODE, MAIL_FROM_SELF)
      : MAIL_FROM_SELF,
    info: '',
    sign: initialSignature.libraryId,
    library: '',
    cc: [],
    bcc: [],
    tcc: [],
    title: initialSubject,
    content: isLocalStorage ? getSessionStorage(MAIL_BODY, '') : '',
    to: getDefaultTo(),
    toCheck: getDefaultToCheck(),
    sendTime: '',
  };
  // 表示用ステート
  const [meta, setMeta] = useState(initialMeta);

  const [mailBodyKey, setMailBodyKey] = useState('');

  // アップロードした配列のAPIが返ってくる
  const initialUploadedList = isLocalStorage ? getSessionStorage(MAIL_ATTACHMENTS, []) : [];
  const [uploadedList, setUploadedList] = useState(initialUploadedList);
  useEffect(() => {
    setTestMailParam({ ...testMailParam, files: uploadedList });
    setMailParam({ ...mailParam, files: uploadedList });
  }, [uploadedList]);

  // メール本文
  const mailList = () => {
    let result = '';
    for (let j = 0; j < user.mails.length; j += 1) {
      if (j !== 0) {
        result += ', ';
      }
      result += user.mails[j];
    }
    return result;
  };
  const toSetting = !allFlg ? {
    label: 'To',
    require: true,
    style: { width: '100%' },
    check: meta.toCheck,
    setCheck: (e) => {
      return setMeta({ ...meta, toCheck: e });
    },
    list: meta.to,
    setList: (e) => {
      return setMeta({ ...meta, to: e });
    },
    disabled: taioRirekiFlg,
  } : {
    label: 'To',
    require: true,
    style: { width: '100%', background: '#c8c8c8', pointerEvents: 'none' },
    check: '',
    setCheck: '',
    list: mailList(user.mails),
    setList: '',
    disabled: taioRirekiFlg,
  };
  const setting = {
    from: {
      label: 'From',
      state: meta.from,
      setState: (e) => {
        const mailFromCode = Number(e.target.value);
        if (mailFromCode === MAIL_FROM_INFO) {
          dispatch(
            isOpen({
              message: '署名は手動で入力してください。',
              status: 'warning',
            }),
          );
        }
        const setBaseMailParam = (s) => {
          if (s.mailFromCode !== mailFromCode) {
            if (s.mailFromCode === MAIL_FROM_INFO) {
              delete s.infoCode;
            } else {
              delete s.signatureLibraryId;
              delete s.signature;
            }
          }
          return { ...s, mailFromCode };
        };
        setTestMailParam(setBaseMailParam);
        setMailParam(setBaseMailParam);
        setMeta((s) => {
          if (s.mailFromCode !== mailFromCode) {
            if (mailFromCode === MAIL_FROM_INFO) {
              delete s.info;
            } else {
              delete s.sign;
            }
          }
          return { ...s, info: '', sign: '', from: mailFromCode };
        });
      },
      constants: mailSetting.from.constants,
      style: { width: '33%' },
    },
    info: {
      label: 'info',
      require: true,
      state: meta.info,
      setState: (e) => {
        const infoCode = Number(e.target.value);
        setTestMailParam({ ...testMailParam, infoCode });
        setMailParam({ ...mailParam, infoCode });
        return setMeta({ ...meta, info: infoCode });
      },
      constants: mailSetting.info.constants,
      style: { width: '33%' },
    },
    sign: {
      // 署名は画面上では表示されないが、裏側で処理する
      label: '署名',
      require: true,
      state: meta.sign,
      setState: (e) => {
        const signatureLibraryId = Number(e.target.value);
        setTestMailParam({
          ...testMailParam,
          signatureLibraryId,
          signature: signature.libraries.find(s => {
            return s.libraryId === signatureLibraryId;
          })?.mailBody,
        });
        setMailParam({
          ...mailParam,
          signatureLibraryId,
          signature: signature.libraries.find(s => {
            return s.libraryId === signatureLibraryId;
          })?.mailBody,
        });
        return setMeta({ ...meta, sign: signatureLibraryId });
      },
      constants: mailSetting.sign.constants,
      style: { width: '24.5%' },
    },
    library: {
      type: 'library',
      label: 'ライブラリの利用',
      state: meta.library,
      function: (e) => {
        // ライブラリ管理のテーブルの行だけだと情報が足りないため
        libraryGetApi(e.libraryId).then((res) => {
          const {
            libraryId,
            mailSubject,
            mailBody,
            libraryName,
            attachments,
          } = res.data;
          const setBaseMailParam = (prevState) => ({
            ...prevState,
            libraryId,
            mailSubject,
            mailBody,
          });
          setTestMailParam(setBaseMailParam);
          setMailParam(setBaseMailParam);
          setMeta((prevState) => ({
            ...prevState,
            library: libraryName,
            title: mailSubject,
            content: mailBody,
          }));
          setMailBodyKey(`libraryId:${libraryId}`);
          setEditorState(mailBody);

          // 添付はuseEffectで反映する
          const files = attachments.map((attachment) => {
            const { fileName, file, mimeType } = attachment;
            return { fileName, file, mimeType };
          });
          setUploadedList(files);
        });
      },
      // constants: mailSetting.library.constants,
      style: { width: '33%' },
      disabled: taioRirekiFlg,
    },
    to: toSetting,
    cc: {
      label: 'CC',
      state: meta.cc,
      setState: (e) => {
        const ids = e.map((u) => u.userId);
        setMailParam({ ...mailParam, ccUserIds: ids });
        return setMeta({ ...meta, cc: e });
      },
      style: { width: '33%' },
      disabled: allFlg || taioRirekiFlg,
    },
    bcc: {
      label: 'BCC',
      state: meta.bcc,
      setState: (e) => {
        const ids = e.map((u) => u.userId);
        setMailParam({ ...mailParam, bccUserIds: ids });
        return setMeta({ ...meta, bcc: e });
      },
      style: { width: '33%' },
      disabled: allFlg || taioRirekiFlg,
    },
    tcc: {
      label: 'テストCC',
      state: meta.tcc,
      setState: (e) => {
        const ids = e.map((u) => u.userId);
        setTestMailParam({ ...testMailParam, ccUserIds: ids });
        return setMeta({ ...meta, tcc: e });
      },
      style: { width: '33%' },
      disabled: taioRirekiFlg,
    },
    title: {
      label: '件名',
      state: meta.title,
      setState: (e) => {
        setTestMailParam({ ...testMailParam, mailSubject: e });
        setMailParam({ ...mailParam, mailSubject: e });
        return setMeta({ ...meta, title: e });
      },
      style: { width: `${isSp ? '100%' : '74.5%'}` },
      require: true,
      disable: taioRirekiFlg,
      maxLengthInt: 250,
    },
    sendTime: {
      type: 'sendTime',
      label: '送信開始時間',
      style: { width: '25%' },
      state: meta.sendTime,
      setState: (e) => {
        setMailParam({
          ...mailParam,
          timerStartAt: e.replace(/\//g, '-'),
          mailTypeCode: !allFlg
            ? MAIL_TYPE_INDIVIDUAL_TIMER
            : MAIL_TYPE_SIMULTANEOUS_TIMER,
        });
        return setMeta({ ...meta, sendTime: e });
      },
      disabled: taioRirekiFlg,
    },
    content: {
      label: '',
      state: meta.content,
      setState: (e) => {
        setTestMailParam({ ...testMailParam, mailBody: e });
        setMailParam({ ...mailParam, mailBody: e });
        setMeta({ ...meta, content: e });
      },
      wrapper: 'small',
      toolbar: true,
    },
  };

  // LocalStorageの値をセットするためにメール本文のエリアを再レンダリング
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      if (mailBodyKey !== initialMeta.content) {
        setMailBodyKey(initialMeta.content);
      }
    } else {
      isFirstRender.current = false;
    }
  }, [setting.content]);

  // 未送信メールのキャンセル
  const cancelSendMail = () => {
    addLoading(MAIL_EDIT_WINDOW);
    sendmailCancelApi({ mailCancelCode: 3, actionId, customerId: user.customerId })
      .then(() => {
        handleClose();
        refleshCallBack();
      })
      .catch((err) => console.error(err))
      .finally(() => removeLoading(MAIL_EDIT_WINDOW));
  };
  // 未送信メールの送信時間の変更
  const updateSendMail = (date) => {
    addLoading(MAIL_EDIT_WINDOW);
    const tmpDate = date ? new Date(date) : new Date();
    const deliveryAt = dateFormatter(tmpDate, 'YYYY/MM/DD hh:mm');
    let params = {
      mailQueueId,
      deliveryAt,
    };
    if (rirekiAllRsvFlg) {
      params = { ...params, isIndividualChange: 1, customerId };
    }
    sendmailUpdateApi(params)
      .then(() => {
        handleClose();
        refleshCallBack();
      })
      .catch((err) => console.error(err))
      .finally(() => removeLoading(MAIL_EDIT_WINDOW));
  };

  useEffect(() => {
    // 対応履歴から開いた場合はスキップ(署名は別で反映させる)
    if (taioRirekiFlg) return;
    const setSignatureParam = (data) => {
      const { libraries: signatureArray = [] } = data;
      if (signatureArray.length === 0) return;
      // ローカルストレージにデータがあればそちらを使用 / Fromに変更ない場合そのまま
      if (initialSignature.libraryId && mailFromCodeRef.current === mailParam.mailFromCode) return;

      // ローカルストレージに使用した署名がなければ一番上の署名をセットする
      const { libraryId: firstSignatureId } = signatureArray[0];
      const initSign = (isLocalStorage && isShowSignature && meta.sign)
        ? meta.sign : firstSignatureId;
      const setBaseMailParam = (prevState) => ({
        ...prevState,
        signatureLibraryId: initSign,
        signature: !allFlg
          ? signatureArray.find(s => { return s.libraryId === initSign; })?.mailBody
          : null,
      });
      setTestMailParam(setBaseMailParam);
      setMailParam(setBaseMailParam);
      setMeta((prevState) => ({ ...prevState, sign: initSign }));
      if (!signature?.libraries?.[0]?.libraryId) dispatch(libraryGetSigniture(data));
      mailFromCodeRef.current = mailParam.mailFromCode;
    };

    const setSignatureAgentParam = (data) => {
      if (data.length === 0) return;
      // ローカルストレージにデータがあればそちらを使用 / Fromに変更ない場合そのまま
      if (initialSignature.libraryId && mailFromCodeRef.current === mailParam.mailFromCode) return;

      // ローカルストレージに使用した署名がなければ一番上の署名をセットする
      const { libraryId: firstSignatureId } = data[0];
      const initSign = (isLocalStorage && isShowSignature && meta.sign)
        ? meta.sign : firstSignatureId;
      const setBaseMailParam = (prevState) => ({
        ...prevState,
        signatureLibraryId: initSign,
        signature: null,
      });
      setTestMailParam(setBaseMailParam);
      setMailParam(setBaseMailParam);
      setMeta((prevState) => ({ ...prevState, sign: initSign }));
      mailFromCodeRef.current = mailParam.mailFromCode;
    };

    // 署名の取得(通常の場合)
    mailFunctions.getSignature(
      signature?.libraries?.[0]?.libraryId ? signature : null,
      customerId,
      mailParam.mailFromCode,
      setMailSetting,
      mailParam.mailFromCode === MAIL_FROM_SELF ? setSignatureParam : setSignatureAgentParam,
    );

    // テストCCの取得
    const setTestCC = (res) => {
      const tmp = [];
      const { managers = [], centerManagers = [], subCenterManagers = [] } = res.data;
      if (managers.length) managers.forEach((m) => tmp.push(m));
      if (centerManagers.length) centerManagers.forEach((m) => tmp.push(m));
      if (subCenterManagers.length) subCenterManagers.forEach((m) => tmp.push(m));
      const testCCUsers = tmp.filter((u) => u.userId !== currentUserId);
      setTestMailParam((s) => ({ ...s, ccUserIds: testCCUsers.map((u) => u.userId) }));
      setMeta((s) => ({ ...s, tcc: testCCUsers }));
    };
    if (mainDivision.id) mailFunctions.getTestCC(mainDivision.id, setTestCC);
  }, [customerId, mailParam.mailFromCode]);
  useEffect(() => {
    if (deepEqual(defaultAddressesObj, defaultAddressesObjRef.current)) return;
    defaultAddressesObjRef.current = defaultAddressesObj;
    setMeta({ ...meta, to: getDefaultTo() });
  }, [defaultAddressesObj]);

  useEffect(() => {
    setEditorState(getSessionStorage(MAIL_BODY, ''));
    return () => setEditorState('');
  }, []);

  // 送信メール情報の取得
  useEffect(() => {
    if (!open || !actionId) return;
    isSp ? addLoading('taiouRireki') : addLoading(MAIL_EDIT_WINDOW);
    sendmailGetMailDetailApi({ actionId })
      .then(async (res) => {
        if (!res.data.info) return;
        const {
          mailFromCode,
          infoCode,
          libraryId,
          libraryName,
          ccUsers = [],
          bccUsers = [],
          signatureNumber,
          mailSubject,
          mailBody,
          sendAt,
          mailCode,
          attachments,
        } = res.data.info;
        const ccUserIds = ccUsers.map((u) => u.id);
        const bccUserIds = bccUsers.map((u) => u.id);

        // 署名の取得(対応履歴から開いた場合)
        mailFunctions.getSignature(
          signature?.libraries?.[0]?.libraryId ? signature : null,
          customerId,
          mailFromCode,
          setMailSetting,
        );

        const setBaseMailParam = (isTest) => ((prevState) => {
          const tmp = {
            ...prevState,
            mailFromCode,
            infoCode,
            customerId,
            ccUserIds,
            bccUserIds,
            libraryId,
            signatureLibraryId: signatureNumber,
            mailSubject,
            mailBody,
            mailTypeCode: mailCode,
          };
          if (!isTest) return tmp;
          delete tmp.ccUserIds;
          delete tmp.bccUserIds;
          delete tmp.mailTypeCode;
          return tmp;
        });
        setTestMailParam(setBaseMailParam(true));
        setMailParam(setBaseMailParam());
        setMeta((s) => ({
          ...s,
          from: mailFromCode,
          info: infoCode,
          sign: signatureNumber,
          library: libraryName,
          cc: ccUsers.map((u) => ({ userId: u.id, userName: `${u.lastName}${u.firstName}` })),
          bcc: bccUsers.map((u) => ({ userId: u.id, userName: `${u.lastName}${u.firstName}` })),
          title: mailSubject,
          content: mailBody,
          to: mailAddress,
          sendTime: sendAt,
        }));

        // 添付はuseEffectで反映する
        const files = attachments.map((attachment) => {
          const { fileName, file, mimeType } = attachment;
          return { fileName, file, mimeType };
        });
        setUploadedList(files);
        setEditorState(mailBody);
        setMailBodyKey(`mailQueueId${mailQueueId}mailAddress${mailAddress}`);
        // 一斉予約メールかどうか判定
        if (taioRirekiFlg && mailCode === MAIL_TYPE_SIMULTANEOUS_TIMER) {
          setRirekiAllRsvFlg(true);
        } else {
          setRirekiAllRsvFlg(false);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        isSp ? removeLoading('taiouRireki') : removeLoading(MAIL_EDIT_WINDOW);
      });
  }, [mailQueueId, mailAddress]);

  if (isSp) {
    return !hasLoading('taiouRireki') && (
      <MailCreateWindowComponentSp
        open={open}
        handleClose={handleClose}
        user={user}
        mailFunctions={mailFunctions}
        editorState={editorState}
        setEditorState={setEditorState}
        isTestSent={isTestSent}
        setIsTestSent={setIsTestSent}
        testMailParam={testMailParam}
        setTestMailParam={setTestMailParam}
        mailParam={mailParam}
        setMailParam={setMailParam}
        meta={meta}
        setMeta={setMeta}
        setting={setting}
        isLocalStorage={isLocalStorage}
        mailBodyKey={mailBodyKey}
        setMailBodyKey={setMailBodyKey}
        uploadedList={uploadedList}
        setUploadedList={setUploadedList}
        taioRirekiFlg={taioRirekiFlg}
      />
    );
  }

  return (
    <MailCreateWindowComponent
      open={open}
      handleClose={handleClose}
      user={user}
      mailFunctions={mailFunctions}
      editorState={editorState}
      setEditorState={setEditorState}
      isTestSent={isTestSent}
      setIsTestSent={setIsTestSent}
      testMailParam={testMailParam}
      setTestMailParam={setTestMailParam}
      mailParam={mailParam}
      setMailParam={setMailParam}
      meta={meta}
      setMeta={setMeta}
      setting={setting}
      updateSendMail={updateSendMail}
      cancelSendMail={cancelSendMail}
      allFlg={allFlg}
      taioRirekiFlg={taioRirekiFlg}
      isLocalStorage={isLocalStorage}
      successSendMailCallBack={refleshCallBack}
      mailBodyKey={mailBodyKey}
      uploadedList={uploadedList}
      setUploadedList={setUploadedList}
      isActiveSaveTemplate={isActiveSaveTemplate}
    />
  );
}
