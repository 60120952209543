import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
import CustomerProductsRecommendationsApi from '../../../apis/customer/customerProductsRecommendationsApi';

import { isOpen } from '../../../store/common/apiMessageSlice';

import { classNames } from '../../../commonFunction';

import commonStyles from '../../styles';
import { getMrCheck } from '../../../commonFunction/getEnv';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '2px 8px',
    marginLeft: 8,
  },
});

export default function CustomerObi(props) {
  const { input } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // GET CustomerProductsRecommendations
  const customerProductsRecommendationsFunction = async (customerId, isBand) => {
    setIsLoading(true);
    await CustomerProductsRecommendationsApi(customerId, isBand)
      .then(() => {
        dispatch(
          isOpen({
            message: '成功しました。',
            messageList: [
              'マイソクレコメンドシステムからメールを送りました。',
              'メールをご確認ください。',
            ],
            status: 'info',
          }),
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClick1 = () => {
    // API的にはオビあり、オビなしあるが、送られてくるリンクが開けない
    // メールで確認頂くように変更する
    customerProductsRecommendationsFunction(input.customer.customerId, 1);
  };

  const buttonClasses = isLoading
    ? [baseClasses.buttonsPrimary, classes.button, baseClasses.loading]
    : [baseClasses.buttonsPrimary, classes.button];

  const buttonDisabled = isLoading || getMrCheck();

  return (
    // <Grid className={classes.root}>
    <Button
      variant="contained"
      disabled={buttonDisabled}
      className={classNames(...buttonClasses)}
      onClick={handleClick1}
    >
      マイソク取得
    </Button>
    // </Grid>
  );
}
