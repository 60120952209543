import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

import { getEnvVariable, getMrCheck } from '../../../../commonFunction/getEnv';

import KEYMALIS_CONTRACT_STATUS from '../../../../constants/contractInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  table: {
    width: '100%',
  },
  tr: {
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  th: {
    padding: '0 8px 10px',
    [commonTheme.breakpoints.up('md')]: {
      color: '#8C8C8C',
      borderBottom: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #8C8C8C',
      textAlign: 'center',
    },
  },
  td: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '16px 8px',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px solid #C8C8C8',
      fontWeight: '700',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
    '&:nth-child(4)': {
      textAlign: 'center',
      '&::before': {
        content: '""',
        display: 'block',
        width: 1,
        height: '76%',
        background: '#C8C8C8',
        position: 'absolute',
        top: '12%',
        left: 0,
      },
    },
    '&:nth-child(5)': {
      textAlign: 'center',
    },
  },
  link: {
    color: theme.palette.primaryColor,
  },
}));

export default function ContractInformationTable(props) {
  const {
    list,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  const keymalisDaijinLinkFlg = !isSP && !getMrCheck();

  const getContractStatus = (statusCode) => {
    return KEYMALIS_CONTRACT_STATUS[statusCode];
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`${baseClasses.title4} ${classes.th}`}>
              物件情報
            </TableCell>
            <TableCell className={`${baseClasses.title4} ${classes.th}`}>
              日付
            </TableCell>
            <TableCell className={`${baseClasses.title4} ${classes.th}`}>
              ステータス
            </TableCell>
            {keymalisDaijinLinkFlg && (
              <>
                <TableCell className={`${baseClasses.title4} ${classes.th}`}>
                  &nbsp;
                </TableCell>
                <TableCell className={`${baseClasses.title4} ${classes.th}`}>
                  &nbsp;
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <TableRow key={row.contractId} className={classes.tr}>
              <TableCell className={classes.td}>{row.productName}</TableCell>
              <TableCell className={classes.td}>
                {row.contractDate.substr(0, 10)}
              </TableCell>
              <TableCell className={classes.td}>
                {getContractStatus(row.contractStatus)}
              </TableCell>
              {keymalisDaijinLinkFlg && (
                <>
                  <TableCell className={classes.td}>
                    <Link
                      href={`${getEnvVariable('KEYMALIS_BASE_URL')}contract/detail/${row.contractId}`}
                      target="_blank"
                      className={classes.link}
                    >
                      キマリス
                    </Link>
                  </TableCell>
                  <TableCell className={classes.td}>
                    <Link
                      href={`${getEnvVariable('DAIJIN_BASE_URL')}contract/${row.contractId}?tab=1`}
                      target="_blank"
                      className={classes.link}
                    >
                      ダイジン
                    </Link>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
